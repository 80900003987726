<template>
  <div class="h-full w-full">
      <app-header />
      <div>
        <slot name="pageheader" />
      </div>
      <div class="container w-full px-5 pt-4 h-full">
        <slot />
      </div>
      <!--<app-footer />-->
    </div>
</template>

<script lang="ts" setup>

</script>

<style>
html, body, #__nuxt {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>